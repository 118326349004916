:root {
    --main-bg-color: #010101;
  }

.about-wrapper{
    margin: 0;
    padding: 0;
    background-color: #010101;
}

.suit-img{
    object-fit: cover;
    width: 40%;
    background-color: black;
    position: relative;
}
.hero-section{
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: var(--main-bg-color);

}
.info{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 0;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 20ox;
    color: white;
    background-color: #010101;
    
}

.info-right, .info-left{
    min-width: 400px;
    max-width: 600px;
    text-align: center;
    font-size: 20PX;
    padding: 15px;
}
/* .info-center{
    min-width: 400px;
    max-width: 900px;
    text-align: center;
    padding: 15px;
} */
.titles{
    background-color: var(--main-bg-color);
    color: white;
    width: 100%;
    padding: 15px;
    text-align: center;
}

.age{
    font-size: 60px;
}
.boldy{
 
    position: relative;
    object-fit: cover;
}
.info-left>svg{
    font-size: 100px;
    align-items: center;
    justify-content: center;
}
.info-right>svg{
    font-size: 100px;
    padding: 7px;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1000px) {
.info{ 
  padding: 20px;
 
}
}