.vclass-hero{
    position: relative;
    width: 100%;
    height: 500px;
    object-fit: cover;
}
.car-wrapper{
    display: flex;
    flex-direction: row;
    margin: 20px;
    flex-wrap: wrap;
    gap: 10px;
    background-color: #ffff;
    margin: 0;
    padding: 20px;
  }
  .car-cards{
    display: flex;
    flex-direction: row;
    margin: 20px;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .car-info{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
    margin: 10px;
    width: 300px;
    background-color: #ffff;
    border-radius: 15px;
  }
  .car-img{
    position: relative;
    width: 100%;
    height: 230px;
    object-fit: fil;
  }
  .rates{
    display: flex;
    flex-direction: row;
    width: 95%;
    margin: 5px;
    border-bottom: 1px solid grey;
    padding: 5px;
  }
  .price{
    right: 10px;
    position: absolute;
  }
  .card-button{
    background-color: #462cc7;
    color: #ffff;
    border-radius: 5px;
    padding: 5px;
    font-size: 15px;
    text-decoration: none;
    text-align: center;
  }
  .features{
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffff;
    text-align: center;
    flex-wrap: wrap;
    height: 300px;
  }
  .custom-build-title{
    background-color: black;
    color: white;
    text-align: center;
    padding: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-build-title > h1{
    margin: 0;
  }
  .custom-features{
    
      flex-wrap: wrap;
      height: 100%;
  }
  .features-title{
    font-size: 40px;
  }
  .features.text{
    font-size: 30px;
  }
  .whatsapp{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
/* 
  .gallery{
    margin: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: white;
  }
  .gallery-cards{
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
  }
  .gallery-title{
    font-size: 50px;
    text-align: center;
    justify-content: center;
  }
  .gallery-img{
    display: flex;
    padding: 20px;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .gallery-cards, .gallery-img{
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
  }

  .gallery-cards, .gallery-img:hover{
    filter: opacity(0.8);
  }
  @media screen and (max-width: 960px) {
    .gallery-cards{
      -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    }

  }
  @media screen and (max-width: 460px) {
    .gallery-cards{
      -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
    padding: 0 12px;
    }

  } */


@media screen and (max-width: 1000px) {
  .features{ 
    height:100%;
  }
  .features-title{
    font-size: 30px;
    padding: 10px;
  }
  .features-text{

    padding: 10px;
  }
  }