body{
  margin: 0;
  padding: 0;
}
h1, h2{
  font-family: 'Spectral', serif;
}

p{
  font-family: 'Roboto', sans-serif;
}