:root{
    --primary: black;
}

.btn{
    flex: 1 1 auto;
  margin: 10px;
  padding: 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
 /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  /* box-shadow: 0 0 20px #eee; */
  border-radius: 10px;
}



.btn--primary {
    background-color: var(--primary);
    color: black;
    border: 1px solid var(--primary);
}

.btn--outline{
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium{
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large{
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover{
    background-position: right center; 
    /* change the direction of the change here */
    cursor: pointer;

}
.btn-1 {
    background-image: linear-gradient(to right, #D7B25B 0%, #462cc7 100%);
  }

  .btn-2 {
    background-image: linear-gradient(to right, #D7B25B 0%, #0d0d0d 100%);
  }