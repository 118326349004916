body{
    margin: 0;
    padding: 0;
    background-color: #0000;

}

*{
    box-sizing: border-box;
}
.flex-container{
    margin: 0;
    padding: 0;
    height: 100%;
    display: block;
    position: relative;
    
}
.hero-container-form{
    /* background-color: black; */
    margin: 20px;
    height: 100%;
    padding: 0;
    position: relative;
}
.form-tc{
    margin: 10px;
}
.contactForm{
    display: block;
    background-color: white;
    box-shadow: 1px 1px 3px 2px;
    max-width: 400px;
    margin-left: 30px;
    height: 100%;
    padding:  40px;
    margin-left: 70px;
    background-color: #fff;
    padding: 20px;
    margin: 20px;
}
.vehicle-prompt{
    margin: 10px;
}
.options{
    width: 130px;
    height: 100px;
    position: relative;
}
label{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.car-checkbox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px;
}
.checkbox-names{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0;
}
[type=checkbox] { 
    
    opacity: 0;
    display: flex;
    flex-direction: row;
    width: 0;
    height: 0;
    margin: 0;
  }

  
  /* IMAGE STYLES */
  [type=checkbox] + img{
    cursor: pointer;
  }

  
  /* CHECKED STYLES */
  [type=checkbox]:checked + img{
    outline: 2px solid #d7b25b;
  }
 
.marketing{
    opacity: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    margin: 0;
}
input, textarea{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px auto;
    padding: 8px;
    background-color: #f1f1f1;
    border:1px solid #d7b25b;
}
.date-time{
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 0px;
}
.date-option{
    margin-right: 5px;
}
textarea{
    height: 150px;
}
form span{
    color: #d7b25b;
    
}
.contact-form{
    height: 100%;
    background-color: rgba(241, 241, 241, 0.8);
    padding: 20px;
    border-radius: 10px;
}
.contact--{
    color: #d7b25b;
    /* text-shadow: 2px 2px #f1f1f1; */
    margin-top: 0px;
}
form button{
    height: 40px;
    width: 30%;
    align-items: center;
    cursor: pointer;
    color: white;
    background-color:#d7b25b ;
}
.row{
    background-color: #9ddea2;
    text-align: center;
    align-items: center;
    padding: 5px;
    margin: 15px;
    height: 500px;
    width: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}
.go-back{
    font-size: 12px;
}
video{
    object-fit: cover;
    min-width: 100%;
    position: fixed;
    z-index: -1;
  }

  .contact-title, .contact-sub{
    color: #d7b25b;
    text-align: center;
    margin-top: 30px;
    /* text-shadow: 4px 4px #d7b25b; */
  }
 
  .contact-title{
    font-size: 50px;
   display: flex;
  
   justify-content: center;
   align-items: center;
   position: relative;

  }
  .title{
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
  }
  .contact-sub{
    font-size: 30px;
   
  }
  .flex-container {
    display: flex;
    margin: 0 20px 0 20px;
}

.flex-child {
    flex: 1;
    float: left;
    margin-right: 40px;
    flex-direction: column;
}  

@media screen and (max-width: 1000px) {
    .flex-container{
        flex-direction: column;
        margin: 0px 0px 0px 0px;
        padding: 20px;
        justify-content: center;
        align-items: center;
        
       
    }
    .hero-container-form, .flex-container{
        margin: 0;
        padding: 0;
        width: 100%;
    }
    .contact-title, .contact-sub{
        color: #d7b25b;
        text-align: center;
        margin-top: 0px;
        
        margin-bottom: 20px;
        margin-left: 0px;
        text-align: center;
        margin-right: 0px;
      }
    .contact-title{
        font-size: 27px;
        width: 100%;
        margin-top: 0px;
        text-shadow: 4px 4px rgb(253, 251, 251);
       
      }
    .contact-sub{
        font-size: 20px;
    }
video{
    height: 300vh;
}
form{
    margin-left: 0px;
    align-items: center;
}
.car-checkbox{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 30px;
}
.flex-child {
    margin-right: 0;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}
    
.flex-container{
    margin: 0;
    padding: 0;
}
.row{
    width: 80%;
}
}