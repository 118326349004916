video{
    object-fit: cover;
    min-width: 100%;
    position: fixed;
    z-index: -1;
    top: 0;
  }
  
  .hero-container{
    /* background: url() center center/cover no-repeat;*/
    height: 100%;
    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 0 100px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  .home{
    width: 100%;
    display: flex;
    justify-content: center;
  } 
  
  .homeTitle {
    text-transform: uppercase;
    background: linear-gradient(to right, black 0%, black 100%);
    /* color: #d7b25b; */
    text-shadow: 4px 2px #d7b25b;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    
  }  

  ul{
    list-style: none;
  }

  .services{
    display: flex;
    flex-direction: row;
    padding: 20px;
    position: relative;
    background-color: #ffff;
    margin: 0;
    padding: 40px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .service-wrapper{
    display: flex;
    flex-direction: row;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 300px;
    margin: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;

   
  }
  .service-cards{
   padding: 0px;
   border-radius: 20px;
  }
  .boldy{
    position: relative;
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0 0;
    padding: 0px;
  }

  .contact-wrapper{
    background-color: black;
    margin: 0;
  }
  .small{
    font-size: 11px;
    font-weight: bold;
    padding: 10px;
  }
  .services-title, .services-text, .booking-link {
    padding: 5px;
    margin: 5px;
  }
  .booking-link{
    display: block;
    margin: 5px;
    text-decoration: none;
  }

  .key-points{
    font-size: 30px;
  }
  .features-content{
    margin: 20px;
    width: 180px;
    flex-wrap: wrap;
  }
  .custom-steps-text{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:0;
  }
  .custom-steps{
    margin: 5px;
  }
  .step-title{
    font-size: 20px;
  }

  .circle{
    min-width: 300px;
    flex-wrap: wrap;
    height: 300px;
    border-radius: 50%;
    border: 2px #d7b25b solid;
    padding: 20px;
  }
  @media screen and (max-width: 960px){
    .homeTitle {
      font-size: 60px;
    }  
    ul{
      list-style: none;
    }
  }
  @media screen and (max-width: 700px){ 
    .homeTitle {
      font-size: 35px;
      
    }  
    .btn-mobile{
      display: block;
      text-decoration: none;
    }

    .btn{
      width: 100%;
    }
    ul{
      list-style: none;
    }
  }
