  .gallery{
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 3px 5px;
  
  }
  .gallery-img{
    width: 100%;

  }
  
  .gallery-cards, .gallery-img{
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
    position: relative;
  }
  .model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out ;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
  }
  .model.open{
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    width: 100%;
    background-color: black;
  }
.model img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    position: relative;
   
    background-color: black;
}
.model.open svg{
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: (0,0,0,0.4);
    color: #ffff;
    cursor: pointer;
    background-color: black;
}
.gallery-img:hover{
    filter: opacity(0.8);
  }
  @media screen and (max-width: 960px) {
    .gallery{
      -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    }

  }
  @media screen and (max-width: 460px) {
    .gallery{
      -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
    padding: 0 12px;
    }

  }