:root{
    --primary: white;
}
.footer-container{
    background-color: black;
    padding: 0;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.footer-subscription{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: var(--primary) ;

}


.footer-subscription-heading{
    margin-bottom: 24px;
    font-size: 24px;
    color: var(--primary)white;

}
.footer-subscription-text{
    margin-bottom: 24px;
    font-size: 20px;
    color: var(--primary);

}

.footer-input{
    padding: 8px 20px;
    border-radius: 4px;
    margin-right: 10px;
    outline: white;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid var(--primary);
}
.footer-links {
    width: 400px;
    max-width: 10000px;
    display: flex;
    justify-content: center;

}

.footer-link-wrapper{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.footer-link-items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    width: 300px;
    box-sizing: border-box;
    align-items: center;
}

.footer-link-items h2{
    margin-bottom: 16px;

}
.footer-link-items >h2{
    color: var(--primary) ;

}

.footer-link-items a {
    color: var(--primary);
    text-decoration: none;
    margin-bottom: 8px;
    align-items: center;


}

.footer-link-items a :hover {
    color: var(--primary);
    transition: 0.3s ease-out;
}
.footer-email-form h2{
    margin-bottom: 32px;

}

.footer-input::placeholder{
    color: #747474 ;
}

.social-icon-link >i{
    color: var(--primary) ;
    font-size: 23px;
}
.social-icon-link{
    color: var(--primary);
    font-size: 23px;}
.social-media{
    max-width: 1000px;
    width: 100%;

}

.social-media-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}

.social-icons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
    color: var(--primary) ;

}

.social-logo{
    color: var(--primary) ;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-self: center;
    margin-bottom: 20px;
}

.website-rights{
    color: var(--primary) ;
    margin-bottom: 16px;

}



@media screen and (max-width: 820px){
    .footer-links{
        padding-top: 2rem;
        
    }
    .footer-input{
        width: 100%;

    }
    .btn{
        width:100%;
    }
    .footer-link-wrapper{
        flex-direction: column;
    }

    .social-media-wrap{
        flex-direction: column;
    }
    .footer-link-items{
        align-items: center;
        display: flex;

        
    }

}